import RedeemIcon from "@mui/icons-material/Redeem";
import AddCardIcon from "@mui/icons-material/AddCard";
// constant
const icons = {
  RedeemIcon,
  AddCardIcon,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const promotion = {
  id: "promotion-group",
  title: "โปรโมชั่น&เครดิต",
  type: "group",
  children: [
    {
      id: "creditcode",
      title: "จัดการเครดิตโค้ด",
      type: "item",
      icon: icons.AddCardIcon,
      url: "/creditcode",
    },
    {
      id: "promotion",
      title: "จัดการโปรโมชั่น",
      type: "item",
      icon: icons.RedeemIcon,
      url: "/promotion",
    },
  ],
};

export default promotion;
