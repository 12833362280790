import dashboard from "./dashboard";
import widget from "./widget";
import application from "./application";
import forms from "./forms";
import elements from "./elements";
import pages from "./pages";
import utilities from "./utilities";
import support from "./support";
import other from "./other";
import account from "./account";
import accountSummary from "./account-summary";
import member from "./member";
import promotion from "./promotion";
import api from "./api";
import setting from "./setting";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, account, promotion, member, accountSummary, api, setting],
};

export default menuItems;
