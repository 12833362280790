// material-ui
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// project imports
import { useDispatch, useSelector } from "store";
import Chip from "@mui/material/Chip";
// assets
import { IconMenu2 } from "@tabler/icons";
import io from "socket.io-client";
import Divider from "@mui/material/Divider";
import { getWithdrawHistory } from "store/slices/user";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const socket = io("https://api.108-bets.co");

const Header = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { withdrawHistory } = useSelector((state) => state.user);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    socket.emit("get-client-count", "get");
    socket.on("withdraw-request-listen", (res) => {
      dispatch(getWithdrawHistory());
    });
    //socket.on("client-count", (res) => {
    //  console.log("client-count", res);
    //});

    return () => {
      socket.off("withdraw-request-listen");
      socket.off("client-count");
    };
  }, []);

  React.useEffect(() => {
    dispatch(getWithdrawHistory());
  }, []);

  React.useEffect(() => {
    setData(withdrawHistory.filter((_trans) => _trans.status === "CHECKING"));
  }, [withdrawHistory]);

  return (
    <>
      <Divider variant="middle" flexItem sx={{ m: 2 }} />
      <Box sx={{ display: { xs: "none", sm: "block" }, ml: 2.5 }}>
        <Chip
          label={data && data.length}
          color={data?.length > 0 ? "error" : "primary"}
          size="large"
          sx={{ mr: 1 }}
        />
        คำขอถอน
      </Box>
      <Divider variant="middle" flexItem sx={{ m: 2 }} />
    </>
  );
};

export default Header;
