import { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Loadable from "ui-component/Loadable";

const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    { path: "/", element: <Navigate to="/dashboard/default" replace /> },
    AuthenticationRoutes,
    LoginRoutes,
    MainRoutes,
  ]);
}
