// third-party
import jwt from "jsonwebtoken";
import axiosex from "axios";
// project imports
import services from "utils/mockAdapter";
import { JWT_API } from "config";

// constant
const JWT_SECRET = JWT_API.secret;
const JWT_EXPIRES_TIME = JWT_API.timeout;

const delay = (timeout) => new Promise((res) => setTimeout(res, timeout));
let users = [];

async function getAdmin() {
  const response = await axiosex.get(`https://api.108-bets.co/login`);
  users = response.data.data;
  return async () => {
    try {
    } catch (error) {
      console.log(error);
    }
  };
}
// ==============================|| MOCK SERVICES ||============================== //

services.onPost("/api/account/login").reply(async (request) => {
  try {
    await delay(500);

    const { username, password } = JSON.parse(request.data);

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = JSON.parse(localUsers);
    }

    const user = await axiosex.post("https://api.108-bets.co/login", {
      username,
      password,
    });

    //const user = users.find((_user) => _user.username === username);

    if (user.data === "notfound") {
      return [400, { message: "ชื่อผู้ใช้งานหรือรหัสผ่านผิด!" }];
    }

    if (user.data === "wrongpass") {
      return [400, { message: "รหัสผ่านผิด!" }];
    }

    if (user.data === "banned") {
      return [400, { message: "พบปัญหาในการเข้าสู่ระบบ กรุณาติดต่อแอดมิน" }];
    }

    const serviceToken = jwt.sign({ userId: user.data.AdminID }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_TIME,
    });

    return [
      200,
      {
        serviceToken,
        user: {
          id: user.data.AdminID,
          username: user.data.username,
          name: user.data.name,
          role: user.data.role,
        },
      },
    ];
  } catch (err) {
    console.error(err);
    return [500, { message: "Server Error" }];
  }
});

services.onPost("/api/account/register").reply(async (request) => {
  try {
    await delay(500);

    const { id, email, password, firstName, lastName } = JSON.parse(
      request.data
    );

    if (!email || !password) {
      return [400, { message: "Enter Your Email & Password" }];
    }

    if (!firstName || !lastName) {
      return [400, { message: "Enter Your Name" }];
    }

    users = [
      ...users,
      {
        id,
        email,
        password,
        name: `${firstName} ${lastName}`,
      },
    ];

    return [200, users];
  } catch (err) {
    console.error(err);
    return [500, { message: "Server Error" }];
  }
});

services.onGet("/api/account/me").reply(async (request) => {
  try {
    const { Authorization } = request.headers;
    if (!Authorization) {
      return [401, { message: "Token Missing" }];
    }

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = JSON.parse(localUsers);
    }

    const serviceToken = Authorization.toString();

    const jwData = jwt.verify(serviceToken, JWT_SECRET);
    const { userId } = jwData;
    const user = await axiosex.post("https://api.108-bets.co/login/check/", {
      userId,
    });

    //const user = users.find((_user) => _user.AdminID === userId);

    if (user.data === "notfound") {
      return [401, { message: "Invalid Token" }];
    }
    return [
      200,
      {
        user: {
          id: user.data.AdminID,
          username: user.data.username,
          name: user.data.name,
          role: user.data.role,
        },
      },
    ];
  } catch (err) {
    return [500, { message: "Server Error XX" + err }];
  }
});
